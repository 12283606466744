body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body, #root {
  margin: 0;
  padding: 0;
  height: 100%;
}

#root {
  padding-top: calc(64px + 16px);
  height: calc(100% - 64px - 16px);
  background-image: url("./img/bg.png");
  background-repeat: repeat;

}

a, a:hover, a:visited {
  color: inherit;
  text-decoration: none;
}

a.text-link, a:hover.text-link, a:visited.text-link {
  color: #2d8eaf;
  text-decoration: underline;
}
